$spaceamounts: (
  0,
  5,
  8,
  10,
  16,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (
  top,
  bottom,
  left,
  right
); // Leave this variable alone

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }
  .mv-#{$space} {
    margin: #{$space}px 0px !important;
  }
  .mh-#{$space} {
    margin: 0px #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }
  .pv-#{$space} {
    padding-top: #{$space}px !important;
    padding-bottom: #{$space}px !important;
  }
  .ph-#{$space} {
    padding-left: #{$space}px !important;
    padding-right: #{$space}px !important;
  }
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

$dimensions: (
  10,
  15,
  18,
  20,
  25,
  28,
  30,
  33,
  35,
  40,
  50,
  60,
  70,
  75,
  80,
  90,
  100
); 

@each $dimension in $dimensions {
  .fx-flex-#{$dimension} {
    flex-basis: $dimension * 1% !important;
  }
}