@use "@angular/material" as mat;
@import 'ngx-toastr/toastr';
/* You can add global styles to this file, and also import other style files */
@include mat.core();
@import "_colors";
@import "_helpers";

$md-uzai: (
  50 : #f1e9fc,
  100 : #dbc8f7,
  200 : #c4a3f1,
  300 : #ac7eeb,
  400 : #9a63e7,
  500 : #8847e3,
  600 : #8040e0,
  700 : #7537dc,
  800 : #6b2fd8,
  900 : #5820d0,
  A100 : #ffffff,
  A200 : #e1d5ff,
  A400 : #bda2ff,
  A700 : #ab89ff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-uzai-accent: (
  50 : #e9fcf1,
  100 : #c8f7db,
  200 : #a3f1c4,
  300 : #7eebac,
  400 : #63e79a,
  500 : #47e388,
  600 : #40e080,
  700 : #37dc75,
  800 : #2fd86b,
  900 : #20d058,
  A100 : #ffffff,
  A200 : #d5ffe1,
  A400 : #a2ffbd,
  A700 : #89ffab,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$uzai-primary: mat.define-palette($md-uzai);
$uzai-accent: mat.define-palette($md-uzai-accent);

// The warn palette is optional (defaults to red).
$uzai-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$uzai-theme: mat.define-light-theme((
  color: (
    primary: $uzai-primary,
    accent: $uzai-accent,
    warn:  $uzai-warn
  ),
  typography: mat.define-typography-config(),
  density: 0
));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($uzai-theme);

html, body { height: 100%; }
body { margin: 0; font-family: 'Source Sans 3', sans-serif; }

*::-webkit-scrollbar {
    display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
}

.container {
  padding: 20px;
  height: calc(100vh);
}
.dialog-container {
  overflow: hidden !important;
  border-radius: 10px !important;
  box-sizing: border-box !important;
  display: block !important;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
      0px 8px 8px -4px rgba(16, 24, 40, 0.04) !important;
  border: 1px solid $lighter !important;
  background-color: $white;
}
.menu-container {
  margin-top: 5px !important;
  max-width: 250px;
  border-radius: 10px !important;
  box-sizing: border-box !important;
  display: block !important;
  border: 1px solid $lighter !important;
  max-height: 400px !important;
}
.menu-item {
  padding: 8px 16px;
}
.menu-item:hover {
  background-color: $white-smoke;
}

// Typography
.page-title {
  font-size: 24px;
  margin-bottom: 8px;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.page-subtitle {
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: $light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.title {
  font-size: 18px;
  line-height: 20px;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text {
  font-size: 14px;
  line-height: 16px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.label {
  font-size: 12px;
  line-height: 14px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: bold;
  color: $light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 5px;
}

.small {
  font-size: 12px;
  line-height: 14px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.huge {
  font-size: 28px;
  line-height: 30px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: bold;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.smaller {
  font-size: 10px;
  line-height: 12px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}
.bold {
  font-weight: bold;
}

a {
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  line-height: 16px;
  color: $main !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
  transition: color 200ms ease-in;
  text-decoration: none !important;
}
a:not(.disabled):hover{
  color: $main-darker !important;
}
a.disabled {
  cursor: not-allowed;
  color: $main-06-opacity !important;
}
.clickable:not(.disabled) {
  cursor: pointer !important;
}
.clickable.disabled {
  cursor: not-allowed!important;
}
.actionable:not(.disabled):hover {
  background-color: rgba($main, .1) !important;
}
.actionable:not(.disabled):hover > * > *,.actionable:not(.disabled):hover > * {
  color: $main !important;
}
.option:not(.disabled).selected {
  background-color: rgba($main, .1) !important;
}
.option:not(.disabled).selected>*>*,
.option:not(.disabled).selected>* {
  color: $main !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right;
}
.upper {
  text-transform: uppercase;
}
.ellipsis-container {
  min-width: 0;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// Angular material overwrites
mat-card {
  overflow: hidden !important;
  padding: 16px !important;
  border-radius: 10px !important;
  box-sizing: border-box !important;
  display: block !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border: 1px solid $lighter !important;
}
mat-divider {
  border-top-color:  $lighter !important;
}
.border-top {
  border-top: 1px solid $lighter !important;
}
.border-right {
  border-right: 1px solid $lighter !important;
}
.border-left {
  border-left: 1px solid $lighter !important;
}
.border-bottom {
  border-bottom: 1px solid $lighter !important;
}
.border-box {
  box-sizing: border-box !important;
}
.uzai-tooltip > *{
  background-color: white !important;
  border: 1px solid rgba($main, .6) !important;
  font-size: 14px;
  line-height: 16px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  color: $dark !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// Flex classes
.fx-row {
  display: flex;
  flex-direction: row;
}

.fx-column {
  display: flex;
  flex-direction: column;
}

.fx-wrap {
  flex-wrap: wrap;
}

.fx-flex {
  flex: 1 1 0.000000001px
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-between {
  align-items: space-between;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-baseline {
  align-items: baseline;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height:100% !important;
}

// Colors
.main {
  color: $main;
}

.main-bg {
  background-color: $main !important;
}

.main-light-opacity{
  color: $main-light-opacity !important;
}
.main-06-opacity {
  color: $main-06-opacity !important;
}

.accent {
  color: $accent;
}

.accent-bg {
  background-color: $accent;
}

.dark {
  color: $dark;
}


.light {
  color: $light;
}

.lighter {
  color: $lighter;
}

.light-bg {
  background-color: $light;
}

.lighter-bg {
  background-color: $lighter;
}

.white {
  color: $white;
}

.red {
  color: $red;
}

.green {
  color: $green;
}

.orange {
  color: $orange;
}

.gold {
  color: $gold;
}

// Buttons
.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height:42px;
  border-radius: 5px;
  padding: 8px 16px;
  border: 1px solid $lighter !important;
  box-sizing: border-box !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  line-height: 16px;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
  cursor: pointer;
  transition: background-color 200ms ease-in;
}
.button:hover {
  background-color: $white-smoke;
}
.button.active {
  background-color: rgba($main,.05) !important;
  border: 1px solid $main !important;
}
.button.disabled {
  border: 1px solid $lighter !important;
  color: $light !important;
  cursor: default;
  pointer-events: none !important;
  * {
    color: $light !important;
  }
}
.button.error {
  border-color:  $red !important;
}

.danger-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 42px;
  border-radius: 5px;
  padding: 8px 16px;
  border: 1px solid $red !important;
  box-sizing: border-box !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  line-height: 16px;
  color: $red;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
  cursor: pointer;
  transition: all 200ms ease-in;
}

.danger-button:not(.disabled):hover {
  background-color: $red;
  color: $white;
}

.danger-button.disabled {
  border: 1px solid $light !important;
  color: $light;
  cursor: default;
  pointer-events: none !important;
}

.main-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 42px;
  border-radius: 5px;
  padding: 8px 16px;
  border: 1px solid $main !important;
  box-sizing: border-box !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  line-height: 16px;
  color: $white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $main;
  cursor: pointer;
  transition: background-color 200ms ease-in;
}

.main-button.disabled {
  background-color: rgba($main,.6);
  border: 1px solid rgba($main, .6) !important;
  box-shadow:none !important;
  cursor:default;
  pointer-events: none !important;
}

.main-button:not(.disabled):hover {
  background-color: $main-darker;
}

// Chips

.chip {
  font-size: 14px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  line-height: 16px;
  border-radius: 20px;
  padding:5px 10px;
}

.chip.plain {
  background-color: $main;
  color: $white;
}
.chip.main {
  background-color: rgba($main, .2);
  color: $main;
}
.chip.main.outline {
  background-color: transparent;
  color: $main;
  border: 1px solid $main;
}
.chip.accent {
  background-color: rgba($accent, .2);
  color: $accent;
}
.chip.dark {
  background-color: rgba($dark, .2);
  color: $dark;
}
.chip.light {
  background-color: rgba($light, .2);
  color: $light;
}
.chip.light.outline {
  background-color: $white;
  color: $light;
  border: 1px solid $light;
}
.chip.green {
  background-color: rgba($green, .2);
  color: $green;
}
.chip.green.plain {
  background-color: rgba($green, 1);
  color: $white;
}
.chip.orange {
  background-color: rgba($orange, .2);
  color: $orange;
}
.chip.orange.plain {
  background-color: rgba($orange, 1);
  color: $white;
}
.chip.red {
  background-color: rgba($red, .2);
  color: $red;
}
.chip.red.plain {
  background-color: rgba($red, 1);
  color: $white;
}
.chip.small {
  font-size: 12px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  line-height: 14px;
  border-radius: 10px;
  padding: 2px 4px;
}

.bullet {
  width:25px;
  height:25px;
  border-radius: 20px;
}
.bullet.small {
  width: 20px;
    height: 20px;
}
.bullet.green {
  background-color: rgba($green, .4);
  color: $green;
}

.bullet.green.plain {
  background-color: rgba($green, 1);
  color: $white;
}

.bullet.orange {
  background-color: rgba($orange, .4);
  color: $orange;
}

.bullet.orange.plain {
  background-color: rgba($orange, 1);
  color: $white;
}

.bullet.red {
  background-color: rgba($red, .4);
  color: $red;
}

.bullet.red.plain {
  background-color: rgba($red, 1);
  color: $white;
}

.uzai-tab {
  font-size: 16px;
  font-family: 'Source Sans 3', sans-serif;
  font-weight: 400;
  line-height: 18px;
  border-radius: 30px;
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 300ms ease-in;
  color: $dark !important;
  border: 1px solid $lighter;
  margin-right:10px;
}
.uzai-tab.small {
  font-size: 14px;
  line-height: 16px;
  padding: 5px 8px;
  // border:1px solid $main;
}
.uzai-tab.selected {
  color:$main!important;
  background-color: rgba($main,.1)!important;
  // border:1px solid $main;
}

mat-form-field {
  margin-bottom: -1.25em;
  font-family: 'Raleway', sans-serif;
}

mat-form-field {
  margin-bottom: -1.25em;
  font-family: 'Raleway', sans-serif;
}
mat-form-field.mat-form-field-appearance-outline  > .mat-mdc-text-field-wrapper {
  border-color: $lighter !important;
  border-radius: 28px !important;
}
.mdc-notched-outline__leading {
  border-top-color: $lighter !important;
  border-left-color: $lighter !important;
  border-bottom-color: $lighter !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.mdc-notched-outline__trailing {
  border-top-color: $lighter !important;
  border-right-color: $lighter !important;
  border-bottom-color: $lighter !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.mat-elevation-z8 {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
}
.mat-mdc-menu-content {
  padding: 0 !important;
  max-height: 400px !important;
}
.mat-mdc-menu-panel {
  max-height:400px !important;
}
.elevation,
.mat-datepicker-content, .mdc-menu-surface {
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04) !important;
}
.mat-mdc-paginator-container {
  font-size: 12px !important;
  line-height: 14px !important;
  font-family: 'Source Sans 3', sans-serif !important;
  font-weight: 400 !important;
  color: $dark !important;
}
.country-flag {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  border: 1px solid $lighter !important;
}

.tab {
  padding:10px;
  font-size: 14px;
  line-height: 16px;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
}

.tab.selected {
  font-weight: bold;
  color: $main;
  border-bottom: 2px solid $main;
}

.file-input {
  display: none;
}

.file-upload {
  padding: 16px !important;
  border-radius: 10px !important;
  box-sizing: border-box !important;
  border: 1px dashed $main !important;
  cursor: pointer;
  transition: background-color 100ms ease-in;
}

.file-upload.active {
  background-color: $main-light-opacity;
}

.main-icon-container {
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: $main;
}

.main-light-icon-container {
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: $main-light-opacity;
}

.upload-icon-container {
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: $main-light-opacity;
}

.error-icon-container {
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: $red-light-opacity;
}

.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='rgb(136, 71, 227)' d='M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z'/%3E%3C/svg%3E") !important;
}

.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='rgb(227, 136, 71)' d='M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z'/%3E%3C/svg%3E")!important;
}

.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='rgb(28, 89, 53)' d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z'/%3E%3C/svg%3E")!important;
}

.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='rgb(227, 71, 84)' d='M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3E%3C/svg%3E")!important;
}

.spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.white-spinner circle {
  stroke: $white !important;
}
.main-spinner circle {
  stroke: $main !important;
}
.badge {
  width: 20px;
  height: 20px;
  background-color: $main;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.uzai-avatar {
  width:30px;
  height:30px;
}