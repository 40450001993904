/* Copyright 2013 Rob Wu <rob@robwu.nl>
 * https://github.com/Rob--W/grab-to-pan.js
 *
 * grab.cur and grabbing.cur are taken from Firefox's repository.
 **/
.grab-to-pan-grab {
  /* cursor: url("grab.cur"), move !important; */
  cursor: -webkit-grab !important;
  cursor: -moz-grab !important;
  cursor: grab !important;
}

.grab-to-pan-grab *:not(input):not(textarea):not(button):not(select):not(:link) {
  cursor: inherit !important;
}

.grab-to-pan-grab:active,
.grab-to-pan-grabbing {
  /* cursor: url("grabbing.cur"), move !important; */
  cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: grabbing !important;
}