$main: #8847E3;
$main-06-opacity: rgba(136, 71, 227, 0.6);
$main-light-opacity: rgba(136, 71, 227, 0.1);
$main-lighter-opacity: rgba(136, 71, 227,0.05);
$main-darker: #512a88;
$main-lighter: #b790ee;
$accent: #13d7ff;
$white: #fff;
$white-light-opacity: rgba(255, 255, 255, 0.1);
$white-smoke: #fcfcfe;
$dark: #13022B;
$light: #747479;
$lighter: #f5f5f7;
$red: #e34754;
$red-light-opacity: rgba(227, 71, 84, .1);
$green:#3a9e31;
$orange: #e38847;
$gold:#FFD700;